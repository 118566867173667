import styled from "@emotion/styled"
import { Formik, FormikErrors } from "formik"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as React from "react"
import { SalesFormBody } from "../../backend/functions/sales/interfaces"
import { SalesFormBodyStruct } from "../../backend/functions/sales/schemas"
import IllustrationAudit from "../../static/illustrations/audits/audit.inline.svg"
import IllustrationCircle from "../../static/illustrations/audits/circle.inline.svg"
import IllustrationSquare from "../../static/illustrations/audits/square.inline.svg"
import IllustrationTriangle from "../../static/illustrations/audits/triangle.inline.svg"
import IllustrationTypesOfTestingShapes from "../../static/illustrations/audits/types-of-testing-shapes.inline.svg"
import ImageCertificationIcon from "../../static/images/audit/certification-icon.inline.svg"
import ImageCertification from "../../static/images/audit/certification.inline.svg"
import ImageTriangle from "../../static/images/audit/triangle.inline.svg"
import { trackFormSubmitEvent } from "../utils/track-ga-event"
import { Footer, Header, ErrorBoundary } from "../components"
import { SEO } from "../components/SEO"
import {
  Box,
  BoxProps,
  Button,
  Container,
  FormMessage,
  LinkAnchor,
  Heading,
  Input,
  Lead,
  List,
  ListItem,
  ListOrdered,
  SubHeading,
  Text,
  TextBold,
  Title,
  Tooltip,
} from "../elements"
import { Colors, Fonts, Icons } from "../materials"
import { sendSalesForm } from "../utils/api/send-sales-form"

export default function ConsultationPage() {
  const TITLE = "Accessibility Audit - Free Accessibility Consultation - Aditus"
  const DESCRIPTION = `If you need a complete report on your webite or to be compliant with ADA, WCAG, or Section 508, an accessibility audit is ideal for you.`
  const KEYWORDS =
    "accessibility, testing, a11y, audit, compliance, checker, consulting"
  const URL = "https://www.aditus.io/accessibility-audit/"
  const OG_IMAGE = "https://www.aditus.io/social/accessibility-audit.png"
  const OG_IMAGE_ALT =
    "Text reading 'Accessibility Audit' with the Aditus logo next to it"

  React.useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://assets.calendly.com/assets/external/widget.js"
    // script.async = true;
    document.body.appendChild(script)
    // Calendly.initBadgeWidget({
    //   url: 'https://calendly.com/joao-aditus/consultation',
    //   text: 'Schedule time with me',
    //   color: '#00a2ff',
    //   textColor: '#ffffff',
    //   branding: false
    // });
    // debugger;
    // setTimeout(() => {
    //   try {
    //     var iframes = document.getElementsByTagName('iframe');
    //     if (iframes.length === 0) {
    //       console.log('exit 1')
    //       return;
    //     }
    //     const iframe = iframes[0];
    //     if (!iframe) {
    //       console.log('exit 2')
    //       return;
    //     }
    //     const iframeWindow = iframe.contentWindow;
    //     if (!iframeWindow) {
    //       console.log('exit 3')
    //       return;
    //     }
    //     const iframeDoc = iframeWindow.document;
    //     const otherhead = iframeDoc.getElementsByTagName("head")[0];

    //     /* specify our style rules in a string */
    //     const cssRules = '.legacy-booking-container { background: red !important }';
    //     const styleElement = document.createElement('style');
    //     styleElement.appendChild(document.createTextNode(cssRules));

    //     otherhead.appendChild(styleElement);
    //   } catch (e) {
    //     console.log('error', e);
    //   }
    // }, 1000);
    // frames[0].document.head.appendChild(styleElement)
  }, [])

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}
        keywords={KEYWORDS}
        jsonLd={{
          "@type": "WebPage",
          url: URL,
          name: TITLE,
          description: DESCRIPTION,
        }}
      >
        <meta property="fb:app_id" content="824671391240758" />
        <meta property="og:url" content={URL} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={TITLE} />
        <meta property="og:image" content={OG_IMAGE} />
        <meta property="og:image:alt" content={OG_IMAGE_ALT} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:site_name" content="Aditus" />
        <meta property="og:locale" content="en_US" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@aditus_a11y" />
        <meta name="twitter:url" content={URL} />
        <meta name="twitter:title" content={TITLE} />
        <meta name="twitter:description" content={DESCRIPTION} />
        <meta name="twitter:image" content={OG_IMAGE} />
        <meta name="twitter:image:alt" content={OG_IMAGE_ALT} />
      </SEO>
      <Header />
      <main>
        <ErrorBoundary>
          <Container pt={11}>
            <Title color={Colors.oc.blue[9]} textAlign="center">
              Free Accessibility Consultation
            </Title>
            <Box mt={4} />
            <Box display="flex" justifyContent="center">
              <Box maxWidth="copy">
                <Lead textAlign="center">
                  Select an available time slot and that's it!
                  <br />
                  We'll be all ears for 45min. Talk to you soon :)
                </Lead>
                <Box mt={2} />
                <Text textAlign="center" fontSize={2}>
                  (We only do one consultation per day, otherwise we wouldn't
                  get any work done)
                </Text>
              </Box>
            </Box>

            {/* <Box mt={4} />
            <Heading color={Colors.oc.blue[9]}>The goals of this call?</Heading>
            <Box maxWidth='copy'>
              <ul>
                <li>Understand your requirements when it comes to accessibility.</li>
                <li>Quickly review your website for accessibility issues.</li>
                <li>See if we are a good fit to work together.</li>
              </ul>
            </Box> */}
          </Container>
          <Box mt={6} />
          <Container maxWidth="l" px={0} pb={10}>
            {/* Calendly inline widget begin */}
            <Box
              className="calendly-inline-widget"
              data-url={`https://calendly.com/joao-aditus/consultation?text_color=${Colors.oc.gray[9]}&primary_color=${Colors.oc.blue[9]}`}
              height={[1070, 1070, 1070, 760]}
            />
            {/* Calendly inline widget end */}

            {/* Calendly badge widget begin */}
            {/* <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" /> */}
          </Container>
          {/* <Box bg={Colors.oc.gray[0]} py={8}>
            <Container maxWidth='m'>
              <Box mt={4} />
              <Heading color={Colors.oc.blue[9]}>The first consultation is on us.</Heading>
              <Box mt={4} />

              <Box maxWidth='copy'>
                <Text>
                  The easiest way to help our clients understand how we work is to
                  provide a free assessment call. Together we will:
                </Text>
              </Box>

              <Box mt={4} />
              <Box>
                <List>
                  <ListItem display="flex" alignItems="center" mb={1}>
                    <Box
                      flex="0 0 auto"
                      width={18}
                      height={18}
                      color={Colors.oc.blue[9]}
                      mr={3}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      css={{
                        svg: { width: '100%' }
                      }}
                    >
                      <Icons.SvgCheckCircle />
                    </Box>
                    <Text>
                      Analyse your website or product.
                    </Text>
                  </ListItem>
                  <ListItem display="flex" alignItems="center" mb={1}>
                    <Box
                      flex="0 0 auto"
                      width={18}
                      height={18}
                      color={Colors.oc.blue[9]}
                      mr={3}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      css={{
                        svg: { width: '100%' }
                      }}
                    >
                      <Icons.SvgCheckCircle />
                    </Box>
                    <Text>
                      Review some accessibility issues.
          </Text>
                  </ListItem>

                  <ListItem display="flex" alignItems="center" mb={1}>
                    <Box
                      flex="0 0 auto"
                      width={18}
                      height={18}
                      color={Colors.oc.blue[9]}
                      mr={3}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      css={{
                        svg: { width: '100%' }
                      }}
                    >
                      <Icons.SvgCheckCircle />
                    </Box>
                    <Text>Make a plan on how to improve.</Text>
                  </ListItem>
                </List>
              </Box>
            </Container>

          </Box> */}
        </ErrorBoundary>
      </main>
      <Footer />
    </>
  )
}
